import { useEffect } from "react";
import {
  setAssessmentQuestion,
  setTestCaseInputQuestion,
  addTakeHomeTaskInputCase,
  addHomeTaskTestCases,
  addHomeTaskTestCaseOutput,
  addHomeTaskTestCaseInputs,
  deleteTestCaseInput,
  deleteTestCaseInputOutput,
  setValidStatusBar,
} from "../../store/assessmentTask/assessmentTask.actions";
import NavigateToSteps from "./NavigateToSteps";
import { useDispatch } from "react-redux";
import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { DataTypes } from "../../types/assessmentTask";
import ArrayInputs from "../../components/ArrayInputs";
const addQuestionTestCase = require("../../images/addQuestionTestCase.svg");

const typeList = [
  { value: "Integer", label: "Integer" },
  { value: "String", label: "String" },
  { value: "Inter Array", label: "Inter Array" },
  { value: "String Array", label: "String Array" },
];

const HomeTaskStepTwo = (props) => {
  const { homeQuestion, validFields, setValidFields } = props;
  const dispatch = useDispatch();

  const addTestCaseInput = (e=null) => {
    dispatch(
      addTakeHomeTaskInputCase({
        questionId: homeQuestion.id,
      })
    );
    e && e.preventDefault();
  };

  const addTestCase = (e) => {
    dispatch(
      addHomeTaskTestCases({
        questionId: homeQuestion.id,
      })
    );
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(
      setValidStatusBar({
        questionId: homeQuestion.id,
        stepOne: true,
        stepTwo: validFields,
      })
    );
  }, [dispatch, homeQuestion.id, validFields]);

  useEffect(() => {
    setValidFields(homeQuestion.formStep === 3);
  }, [homeQuestion.formStep, setValidFields]);

  return (
    <NavigateToSteps>
      <div className="task-home">
        <form className="task-home__container">
          <h3 className="task-home__container__title">
            02. Test Cases & Inputs/Outputs
          </h3>
          <div className="task-home__container__hint">
            <span>*</span> The entered number of inputs will be the same as the
            number of inputs in the test cases.
          </div>
          <div className="task-home__container__inout">Inputs & Outputs</div>
          <div className="task-home__container__inout-hint">
            Here you need to add inputs and outputs that will be when passing
            the assessment with test cases
          </div>
          {homeQuestion?.testCaseInputs?.map((testCaseItem, indx) => {
            return (
              <div key={indx}>
                <div className="task-home__container__group-input">
                  <div className="task-home__container__group-input__basket">
                    {homeQuestion?.testCaseInputs.length >= 2 && (
                      <button
                        onClick={(e) => {
                          dispatch(
                            deleteTestCaseInputOutput({
                              questionId: homeQuestion.id,
                              id: testCaseItem?.id,
                            })
                          );
                          e.preventDefault();
                        }}
                        className="task-home__container__group-input__basket__img"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5 5H4.16667H17.5"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.66602 5.00008V3.33341C6.66602 2.89139 6.84161 2.46746 7.15417 2.1549C7.46673 1.84234 7.89065 1.66675 8.33268 1.66675H11.666C12.108 1.66675 12.532 1.84234 12.8445 2.1549C13.1571 2.46746 13.3327 2.89139 13.3327 3.33341V5.00008M15.8327 5.00008V16.6667C15.8327 17.1088 15.6571 17.5327 15.3445 17.8453C15.032 18.1578 14.608 18.3334 14.166 18.3334H5.83268C5.39065 18.3334 4.96673 18.1578 4.65417 17.8453C4.34161 17.5327 4.16602 17.1088 4.16602 16.6667V5.00008H15.8327Z"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.33398 9.16675V14.1667"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.666 9.16675V14.1667"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                    <label
                      htmlFor="live-task-label"
                      className="label-task-font"
                    >
                      <span>
                        Input name{" "}
                        {homeQuestion?.testCaseInputs?.length > 1
                          ? indx + 1
                          : ""}
                      </span>
                    </label>
                    <input
                      className="live-task-input"
                      type="text"
                      placeholder="Enter input title"
                      value={testCaseItem?.name}
                      onChange={(e) =>
                        dispatch(
                          setTestCaseInputQuestion({
                            ...testCaseItem,
                            name: e.target.value,
                            questionId: homeQuestion?.id,
                          })
                        )
                      }
                    />
                  </div>
                  <div className="task-home__container__group-input__basket">
                    <label
                      htmlFor="live-task-label"
                      className="label-task-font"
                    >
                      Input Type
                    </label>
                    <ReactSelect
                      options={typeList}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(item: any) => {
                        dispatch(deleteTestCaseInput({ questionId: homeQuestion?.id, id: testCaseItem?.id,deleteAll:true }));
                        dispatch(
                          setTestCaseInputQuestion({
                            ...testCaseItem,
                            type: item.label,
                            questionId: homeQuestion?.id,
                          })
                        );
                      }}
                      value={typeList.find(
                        (x) => x.label === testCaseItem?.type
                      )}
                      placeholder="Select input type"
                      styles={selectCustomStyle}
                      id="typeSelect"
                      instanceId="typeSelect"
                    />
                  </div>
                </div>
                <label htmlFor="live-task-label" className="label-task-font">
                  Input Description
                </label>
                <textarea
                  id="lineCounter"
                  rows={4}
                  className="live-task-input"
                  placeholder="Write input description"
                  value={testCaseItem?.description}
                  onChange={(e) =>
                    dispatch(
                      setTestCaseInputQuestion({
                        ...testCaseItem,
                        description: e.target.value,
                        questionId: homeQuestion?.id,
                      })
                    )
                  }
                />
              </div>
            );
          })}
          <div>
            <button onClick={(e) => addTestCaseInput(e)}>
              <img
                className="cross-add-new-input-case"
                src={addQuestionTestCase}
                alt="add-input-task"
              />
              <span className="cross-add-new-input-text">
                Add one more input
              </span>
            </button>
          </div>
          <hr
            className="horizontal-line"
            style={{
              width: "100%",
              background: "#DFE2E6",
              color: "#DFE2E6",
              height: "1px",
              borderWidth: "0",
            }}
          />
          <div className="two-input-task">
            <div>
              <label htmlFor="live-task-label" className="label-task-font">
                <span>Output Type</span>
              </label>
              <ReactSelect
                options={typeList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  dispatch(
                    setAssessmentQuestion({
                      ...homeQuestion,
                      outputType: item.label,
                    })
                  );
                }}
                value={typeList.find(
                  (x) => x.label === homeQuestion.outputType
                )}
                placeholder="Select output type"
                styles={selectCustomStyle}
                id="outputTypeSelect"
                instanceId="outputTypeSelect"
              />
            </div>
          </div>
          <label htmlFor="live-task-label" className="label-task-font">
            Output Description
          </label>
          <textarea
            id="lineCounter"
            rows={4}
            className="live-task-input"
            placeholder="Write output description"
            value={homeQuestion.outputDescription}
            onChange={(e) =>
              dispatch(
                setAssessmentQuestion({
                  ...homeQuestion,
                  outputDescription: e.target.value,
                })
              )
            }
          />
          <hr
            style={{
              width: "100%",
              background: "#DFE2E6",
              color: "#DFE2E6",
              height: "1px",
              borderWidth: "0",
            }}
          />
          <div className="task-home__container__inout">Test Cases</div>
          <div className="task-home__container__inout-hint">
            Test cases allow you to test a candidate’s code against certain
            requirements
          </div>
          {homeQuestion.testCases.map((testCaseQuestion, id) => {
            return (
              <div key={id}>
                <div className="task-home__container__group-test-case">
                  <div className="task-home__container__group-test-case__basket">
                    {homeQuestion.testCases?.length >= 2 && (
                      <button
                        onClick={(e) => {
                          dispatch(
                            deleteTestCaseInput({
                              questionId: homeQuestion.id,
                              id: testCaseQuestion?.id,
                            })
                          );
                          e.preventDefault();
                        }}
                        className="task-home__container__group-test-case__basket__img"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5 5H4.16667H17.5"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.66602 5.00008V3.33341C6.66602 2.89139 6.84161 2.46746 7.15417 2.1549C7.46673 1.84234 7.89065 1.66675 8.33268 1.66675H11.666C12.108 1.66675 12.532 1.84234 12.8445 2.1549C13.1571 2.46746 13.3327 2.89139 13.3327 3.33341V5.00008M15.8327 5.00008V16.6667C15.8327 17.1088 15.6571 17.5327 15.3445 17.8453C15.032 18.1578 14.608 18.3334 14.166 18.3334H5.83268C5.39065 18.3334 4.96673 18.1578 4.65417 17.8453C4.34161 17.5327 4.16602 17.1088 4.16602 16.6667V5.00008H15.8327Z"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.33398 9.16675V14.1667"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.666 9.16675V14.1667"
                            stroke="#FE4672"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                    <div className="task-home__test-case">
                      {testCaseQuestion?.inputs?.map((testCaseInput, index) => {
                        const testcasInputType =
                          homeQuestion?.testCaseInputs[index]?.type;
                        const testcaseInputName =
                          homeQuestion?.testCaseInputs[index]?.name;
                        return (
                          <div
                            key={testCaseInput.index}
                            className="task-home__test-case__input"
                          >
                            <label
                              htmlFor="live-task-label"
                              className="label-task-font"
                            >
                              <span>
                                Test Case{" "}
                                {testCaseQuestion?.inputs?.length > 1
                                  ? id + 1
                                  : ""}{" "}
                                Input Value{" "}
                                {homeQuestion?.testCaseInputs?.length > 1
                                  ? index + 1
                                  : ""}
                              </span>
                            </label>
                            {testcasInputType === DataTypes.ARRAY_INTEGER ||
                              testcasInputType === DataTypes.ARRAY_STRING ? (
                              <ArrayInputs
                                inputIdentifier={{
                                  id: testCaseQuestion?.id,
                                  index: testCaseInput?.index,
                                  questionId: homeQuestion.id,
                                }}
                                inputName={testcaseInputName}
                                inputType={testcasInputType}
                                inputValue={testCaseInput}
                              />
                            ) : (
                              <input
                                className="live-task-input"
                                type={testcasInputType === DataTypes.INTEGER ? 'number' : 'text'}
                                placeholder="Enter input title"
                                value={testCaseInput.name}
                                onChange={(e) => {
                                  dispatch(
                                    addHomeTaskTestCaseInputs({
                                      id: testCaseQuestion?.id,
                                      index: testCaseInput.index,
                                      name: e.target.value,
                                      questionId: homeQuestion.id,
                                    })
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <label htmlFor="live-task-label" className="label-task-font">
                  Output Test Case Value{" "}
                  {testCaseQuestion?.inputs?.length > 1 ? id + 1 : ""}
                </label>
                <input
                  className="live-task-input"
                  type="text"
                  placeholder="Select input type"
                  value={testCaseQuestion.output}
                  onChange={(e) => {
                    dispatch(
                      addHomeTaskTestCaseOutput({
                        ...testCaseQuestion,
                        output: e.target.value,
                        id: testCaseQuestion.id,
                        questionId: homeQuestion.id,
                      })
                    );
                  }}
                />
              </div>
            );
          })}
          <div>
            <button onClick={(e) => addTestCase(e)}>
              <img
                className="cross-add-new-input-case"
                src={addQuestionTestCase}
                alt="delete-input-task"
              />
              <span className="cross-add-new-input-text">
                Add one more test case
              </span>
            </button>
          </div>
          <div className="group-button-task">
            <div>
              <button
                onClick={() =>
                  dispatch(
                    setAssessmentQuestion({
                      ...homeQuestion,
                      formStep: homeQuestion.formStep - 1,
                    })
                  )
                }
                className="back-button-task"
              >
                Back
              </button>
            </div>
            <div>
              <button
                onClick={() =>
                  dispatch(
                    setAssessmentQuestion({
                      ...homeQuestion,
                      formStep: homeQuestion.formStep + 1,
                    })
                  )
                }
                className="button-save"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </NavigateToSteps>
  );
};

export default HomeTaskStepTwo;
