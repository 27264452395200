import { assessmentTask as types } from "../../types";
import produce from "immer";
import { EditorState } from "draft-js";
import { uuidv4 } from "../../config/utils";
import {
  InputTestCase,
  TaskTestCases,
} from "../../types/assessmentTask";

export const initialValue: types.State = {
  formQuestions: false,
  hasFilter: true,
  filteredData: null,
  assessmentName: null,
  assessmentDescription: null,
  isOnline: true,
  deleteTakeHomeTask: null,
  deleteTestCase: null,
  deleteTestCaseInOut: null,
  counter: 1,
  validStatusBar: {},
  database: {
    questionId: "",
    id: 0,
    title: "",
    packages: null,
    description: "",
    script: "",
  },
  questions: [
    {
      id: uuidv4(),
      formStep: 1,
      validStatusBar: {},
      database: {
        questionId: "",
        id: 0,
        title: "",
        packages: null,
        description: "",
        script: "",
      },
      databases: [
        {
          questionId: "",
          id: 0,
          title: "",
          packages: null,
          description: "",
          script: "",
        },
      ],
      nameQuestion: "Untitled question",
      languages: null,
      packages: null,
      isIncludeTestCases: false,
      questionDescription: null,
      candidateInstruction: EditorState.createEmpty(),
      starterCode: null,
      outputType: "",
      outputDescription: "",
      testCaseInputs: [
        {
          id: 0,
          name: "",
          type: "",
          description: "",
        },
      ],
      testCases: [
        {
          id: 0,
          inputs: [
            {
              index: 0,
              name: null,
            },
          ],
          output: null,
        },
      ],
    },
  ],
  assessmentQuestion: {
    id: "",
    formStep: 1,
    database: {
      questionId: "",
      id: 0,
      title: "",
      packages: null,
      description: "",
      script: "",
    },
    databases: [
      {
        questionId: "",
        id: 0,
        title: "",
        packages: null,
        description: "",
        script: "",
      },
    ],
    nameQuestion: "Untitled question",
    languages: null,
    packages: null,
    isIncludeTestCases: false,
    questionDescription: null,
    candidateInstruction: EditorState.createEmpty(),
    starterCode: "",
    outputType: "",
    outputDescription: "",
    validStatusBar: {},
    testCaseInputs: [
      {
        id: 0,
        name: "",
        type: "",
        description: "",
      },
    ],
    testCases: [
      {
        id: 0,
        inputs: [
          {
            index: 0,
            name: "",
          },
        ],
        output: "",
      },
    ],
  },
  testCaseInputQuestion: {
    id: 0,
    name: "",
    type: "",
    description: "",
  },
  addTakeHomeTaskInput: [
    {
      id: 0,
      name: "",
      type: "",
      description: "",
    },
  ],
  testCases: [
    {
      id: 0,
      inputs: [
        {
          index: 0,
          name: "",
        },
      ],
      output: "",
    },
  ],
  testCaseOutputName: {
    id: 0,
    inputs: [
      {
        index: 0,
        name: "",
      },
    ],
    output: "",
  },
  testCaseInputs: [
    {
      index: 0,
      name: "",
    },
  ],
  databases: [
    {
      questionId: "",
      id: 0,
      title: "",
      packages: null,
      description: "",
      script: "",
    },
  ],
};

const reducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.actionsTypes.SET_HAS_FILTER:
      return produce(state, (draft) => {
        draft.hasFilter = action.payload;
      });
    case types.actionsTypes.SET_IS_ONLINE:
      return produce(state, (draft) => {
        draft.isOnline = action.payload;
      });

    case types.actionsTypes.SET_FORM_QUESTIONS:
      return produce(state, (draft) => {
        draft.formQuestions = action.payload;
      });

    case types.actionsTypes.SET_ASSIGNMENT_NAME:
      return produce(state, (draft) => {
        draft.assessmentName = action.payload;
      });

    case types.actionsTypes.SET_ASSIGNMENT_DESCRIPTION:
      return produce(state, (draft) => {
        draft.assessmentDescription = action.payload;
      });

    case types.actionsTypes.SET_FILTERED_DATA:
      return produce(state, (draft) => {
        draft.filteredData = action.payload;
      });

    case types.actionsTypes.VALID_STATUS_BAR:
      return produce(state, (draft) => {
        const { questionId } = action.payload;

        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        if (currentQuestion.id === questionId) {
          currentQuestion.validStatusBar.stepOne = action.payload.stepOne;
          currentQuestion.validStatusBar.stepTwo = action.payload.stepTwo;
          currentQuestion.validStatusBar.stepThree = action.payload.stepThree;
        }
      });

    //_____________set-input-test-case

    case types.actionsTypes.SET_TESTCASE_INPUTQUESTION:
      return produce(state, (draft) => {
        const { questionId, id } = action.payload;

        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        let currentCaseInputs = currentQuestion.testCaseInputs.find(
          (item) => item.id === id
        );

        if (currentQuestion.id === questionId) {
          currentCaseInputs.id = action.payload.id;
          currentCaseInputs.type = action.payload.type;
          currentCaseInputs.name = action.payload.name;
          currentCaseInputs.description = action.payload.description;

          //TODO: If type switches to String then values displayed as [object,object] if previous type is array 
          //  if(currentCaseInputs.type=== DataTypes.STRING){
          //    const targetIndex = currentQuestion.testCaseInputs.findIndex(
          //      (item) => item.id === id
          //    );

          //    currentQuestion.testCases.forEach((testcase) => {
          //      const targetInput = testcase.inputs[targetIndex];
          //      if(Array.isArray(targetInput.name)) 
          //      targetInput.name = null;
          //    });     
          //  } 
        }
      });

    case types.actionsTypes.ADD_TAKEHOME_TASKINPUT:
      return produce(state, (draft) => {
        const { questionId } = action.payload;
        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        currentQuestion.testCaseInputs = [
          ...currentQuestion.testCaseInputs,
          { ...InputTestCase, id: Math.floor(Math.random() * 1000000) },
        ];
        currentQuestion.testCases.forEach((item) => {
          item.inputs = [
            ...item.inputs,
            {
              index: Math.floor(Math.random() * 1000000),
              name: null,
            },
          ];
        });
      });

    case types.actionsTypes.SET_DATABASE:
      return produce(state, (draft) => {
        const { questionId, id } = action.payload;

        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        let currentDatabase = currentQuestion.database;

        if (currentQuestion.id === questionId) {
          currentDatabase.id = action.payload.id;
          currentDatabase.title = action.payload.title;
          currentDatabase.packages = action.payload.packages;
          currentDatabase.description = action.payload.description;
          currentDatabase.script = action.payload.script;
        }
      });

    case types.actionsTypes.ADD_DATABASE:
      return produce(state, (draft) => {
        const { questionId } = action.payload;
        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        currentQuestion.databases = [
          {
            ...currentQuestion.database,
            ...action.payload,
            id: Math.floor(Math.random() * 1000000),
          },
        ];
      });

    //_____________set-test-case
    case types.actionsTypes.ADD_HOME_TASK_TEST_CASE_INPUTS:
      return produce(state, (draft) => {
        const { questionId, id, index } = action.payload;
        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );
        let currentCaseInput = currentQuestion.testCases.find(
          (item) => item.id === id
        );

        let currentTestCaseInput = currentCaseInput.inputs.find(
          (item) => item.index === index
        );

        currentTestCaseInput.name = action.payload.name;
      });

    case types.actionsTypes.ADD_HOME_TASK_TEST_CASE_OUTPUT:
      return produce(state, (draft) => {
        const { questionId, id } = action.payload;

        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );

        let currentCaseInput = currentQuestion.testCases.find(
          (item) => item.id === id
        );

        if (currentQuestion.id === questionId) {
          currentCaseInput.id = id;
          currentCaseInput.inputs = action.payload.inputs;
          currentCaseInput.output = action.payload.output;
        }
      });

    case types.actionsTypes.ADD_HOME_TASK_TEST_CASES:
      return produce(state, (draft) => {
        const { questionId } = action.payload;
        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );
        currentQuestion.testCases = [
          ...currentQuestion.testCases,
          {
            ...TaskTestCases,
            inputs: currentQuestion.testCaseInputs.map(() => ({
              index: Math.floor(Math.random() * 1000000),
              name: "",
            })),
            id: Math.floor(Math.random() * 1000000),
          },
        ];
      });

    case types.actionsTypes.SET_ASSESSMENT_QUESTION:
      return produce(state, (draft) => {
        const { id } = action.payload;
        let currentQuestion = draft.questions.find((item) => item.id === id);

        currentQuestion.formStep = action.payload.formStep;
        currentQuestion.validStatusBar = action.payload.validStatusBar;
        currentQuestion.nameQuestion = action.payload.nameQuestion;
        currentQuestion.packages = action.payload.packages;
        currentQuestion.languages = action.payload.languages;
        currentQuestion.database = action.payload.database;
        currentQuestion.testCaseInputs = action.payload.testCaseInputs;
        currentQuestion.testCases = action.payload.testCases;
        currentQuestion.questionDescription =
          action.payload.questionDescription;
        currentQuestion.isIncludeTestCases = action.payload.isIncludeTestCases;
        currentQuestion.starterCode = action.payload.starterCode;
        currentQuestion.candidateInstruction =
          action.payload.candidateInstruction;
        currentQuestion.outputDescription = action.payload.outputDescription;
        currentQuestion.outputType = action.payload.outputType;
      });

    case types.actionsTypes.SET_QUESTIONS:
      return produce(state, (draft) => {
        draft.counter = state.counter + 1;
        draft.questions = [...state.questions, ...action.payload];
      });

    case types.actionsTypes.DELETE_TAKE_HOME_TASK:
      return produce(state, (draft) => {
        draft.questions = state.questions.filter((task) => {
          return task.id !== action.payload.id;
        });
      });

    case types.actionsTypes.DELETE_TEST_CASE:
      return produce(state, (draft) => {
        const { questionId, id,deleteAll=false } = action.payload;
        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );
        if(deleteAll){
          currentQuestion.testCases = [
            {
              id: 0,
              inputs: [
                {
                  index: 0,
                  name: null,
                },
              ],
              output: null,
            },
          ];
          return;
        }

        currentQuestion.testCases = currentQuestion.testCases.filter(
          (testCase) => {
            return testCase.id !== id;
          }
        );
        return;
      });

    case types.actionsTypes.DELETE_TEST_CASE_IN_OUT:
      return produce(state, (draft) => {
        const { questionId, id } = action.payload;

        let currentQuestion = draft.questions.find(
          (item) => item.id === questionId
        );
        currentQuestion.testCaseInputs = currentQuestion.testCaseInputs.filter(
          (testCase) => {
            return testCase.id !== id;
          }
        );
        currentQuestion.testCases.forEach(
          (item) =>
          (item.inputs = currentQuestion.testCaseInputs.map((i, index) => ({
            index: item.inputs[index].index,
            name: action.payload.name,
          })))
        );
      });

    case types.actionsTypes.RESET_STATE:
      return { ...initialValue };

    default:
      return state;
  }
};

export default reducer;
