import React, { useEffect, useMemo, useState } from "react";
import { navigate, Link } from "@reach/router";
import AddQuestionTask from "./AddQuestionTask";
import ProgressBar from "./ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { assessmentTaskSelectors } from "../../store/assessmentTask/assessmentTask.selectors";
import {
  DeleteTakeHomeTask,
  resetAssessmentTaskState,
  setAssessmentDescription,
  setAssessmentName,
  setFormQuestions,
  setQuestions,
  setValidStatusBar,
} from "../../store/assessmentTask/assessmentTask.actions";
import { getEnv } from "@urecruits/api";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
const arrowTask = require("../../images/arrowTask.svg");
const circleCheck = require("../../images/circleCheck.svg");
const sendToDraft = require("../../images/sendToDraft.svg");
const consoleErrorIcon = require("../../images/consoleErrorIcon.svg");
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();

import HomeTaskStepOne from "./HomeTaskStepOne";
import HomeTaskStepThree from "./HomeTaskStepThree";
import HomeTaskStepTwo from "./HomeTaskStepTwo";
import HomeTaskStepGeneral from "./HomeTaskStepGeneral";
import { CSSTransition } from "react-transition-group";
import postData from "../../hook/postData";
import DialogCreate from "./DialogCreate";
import DialogDeleteQuestion from "./DialogDeleteQuestion";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import DialogDraftQuestion from "./DialogDraftQuestion";
import cn from "classnames";
import { DataTypes } from "../../types/assessmentTask";
import {
  AuthGuard,
  Button,
  ShowToolTipComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../../hook/fetchData";
import patchData from "../../hook/patchData";
import deleteData from "../../hook/deleteData";

const crossQuestion = require("../../images/crossQuestion.svg");
const crossQuestionWhite = require("../../images/crossQuestionWhite.svg");
const backToGeneralArrow = require("../../images/backToGeneralArrow.svg");

const requiredQuestionFields = [
  "nameQuestion",
  "questionDescription",
  "languages",
];
const HomeTask = (props) => {
  const dispatch = useDispatch();
  const [useTask, setUseTask] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState();
  const [validFieldsButtonCreate, setValidFieldsButtonCreate] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogDeleteQuestion, setDialogDeleteQuestion] = useState(false);
  const [dialogDraftQuestion, setDialogDraftQuestion] = useState(false);
  const [validFields, setValidFields] = useState(false);
  const [canBeCreated, setCanBeCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteId, setDeleteId] = useState({});
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [messageQuestion, setMessageQuestion] = useState("");
  const [editQuesStatus, setEditQuesStatus] = useState('');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpenedTabs, setIsOpenedTabs] = useState(false);
  const params = window.location.pathname?.split("/");
  const id = params.length > 3 && params[3];

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const {
    questions,
    assessmentQuestion,
    formQuestions,
    assessmentName,
    assessmentDescription,
  } = useSelector(assessmentTaskSelectors.getAllState);


  const validatedFields = useMemo(() => {
    // Check if there are any questions
    if (!questions || questions.length === 0) return [];

    return questions.map((question) => {
      // Convert draft content to HTML only if necessary
      const html = draftToHtml(convertToRaw(question.candidateInstruction.getCurrentContent()));

      // Simplify the logic to check for empty or default starterCode
      const isStartCodeRemaining = !question.starterCode || question.starterCode === "<p></p>\n";
      const isCandidateInstructionRemaining = !html || html === "<p></p>\n";

      // Simplify the check for all required fields and validate
      const isStepTwoFieldsValid = requiredQuestionFields.every((field) => Boolean(question[field]));
      const isValid = isStepTwoFieldsValid && !isStartCodeRemaining && !isCandidateInstructionRemaining;
      const isGeneralStepCompleted = Boolean(assessmentName && assessmentDescription);

      dispatch(
        setValidStatusBar({
          questionId: question.id,
          stepOne: isGeneralStepCompleted,
          stepTwo: isStepTwoFieldsValid,
          stepThree: !isStartCodeRemaining && !isCandidateInstructionRemaining,
        })
      );
      return {
        isValidate: isValid,
        id: question.id,
      };
    });
  }, [questions]);


  const [isNameQuestion, setIsNameQuestion] = useState("Untitled question");

  const onDelete = async (e, id, message) => {
    setMessageQuestion(message);
    if (typeof id == 'number') {
      setDeletedQuestions(prev => prev.find(i => i == id) ? prev.filter(i => i != id) : [...prev, id]);
    }

    if (questions.length >= 2) {
      return dispatch(
        DeleteTakeHomeTask({
          id: id,
        })
      );
    } else {
      return dispatch(resetAssessmentTaskState());
    }
  };

  useEffect(() => {
    if (typeof activeTab === "undefined") {
      setActiveTab(0);
    }
  }, [questions, activeTab]);

  const handleClickActiveTab = (e, index) => {
    const { tab } = e.target.dataset;
    if (typeof activeTab === "undefined") {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
    e.stopPropagation();
  };

  const requiredFieldByCreateButton = () => {
    return questions.every((homeQuestion) => {
      return homeQuestion.validStatusBar.stepThree === true;
    });
  };

  const onDialog = () => {
    if (
      localStorage.getItem("prevRoute") ===
      "/recruitment/position-workflow/create"
    ) {
      localStorage.setItem("prevRoute", "");
      navigate("/recruitment/position-workflow/create");
    } else {
      navigate("/coding-assessments");
    }
  };

  const isEdit = () => {
    const includeId = window.location.pathname.split("/").slice(-1)[0];
    return "take-home-task" === includeId;
  };

  const onSave = async (e) => {
    setValidFieldsButtonCreate(true);
    try {
      if (canBeCreated) {
        setIsLoading(true)
        if (deletedQuestions.length) {
          deletedQuestions.forEach(async id => {
            await deleteData(`${API_ASSESSMENT}/api/take-home-task/question/${id}`, {});
          })
        }
        const taskId = id
        const questionBody = {
          ...(taskId && { taskId }),
          name: assessmentName,
          description: assessmentDescription,
          questions: questions?.map((item) => {
            const sendQuestion = {
              ...((taskId && editQuesStatus !== 'DRAFT') && { id: item.id }),
              name: item.nameQuestion,
              languageId: item.languages?.id,
              packageId: item.packages ? item.packages.id : null,
              databaseId: item?.database?.id,
              database: {
                title: item.database.title,
                description: item.database.description,
                packageId: item.database.packages ? item.database.id : null,
                script: item.database.script,
              },
              description: item.questionDescription,
              candidateInstruction: JSON.stringify(
                draftToHtml(
                  convertToRaw(item.candidateInstruction.getCurrentContent())
                )
              ),
              starterCode: item.starterCode,
              outputType: item.outputType,
              outputDescription: item.outputDescription,
              testCaseInputs: JSON.stringify(
                item.testCaseInputs?.map((item) => {
                  if (item?.name || item?.description || item?.type) {
                    return {
                      name: item.name,
                      description: item.description,
                      type: item.type,
                    };
                  }
                })
              ),
              testcases: item.testCases?.map((testCase) => {
                if (testCase.output) {
                  return {
                    id: testCase.id,
                    output: testCase.output,
                    input: JSON.stringify(
                      testCase.inputs.flatMap((i, index) => {
                        const testCaseInputType = item?.testCaseInputs?.[index]?.type;
                        let testCaseInputName = item?.testCaseInputs?.[index]?.name;
                        let testCaseInputValue = i.name;

                        if (testCaseInputType === DataTypes.ARRAY_INTEGER || testCaseInputType === DataTypes.ARRAY_STRING) {
                          testCaseInputValue = testCaseInputValue.map(el => testCaseInputType == DataTypes.ARRAY_INTEGER ? Number(el.value) : String(el.value));
                        }
                        else if (testCaseInputType === DataTypes.INTEGER) {
                          testCaseInputValue = Number(i.name);
                        }

                        return {
                          [testCaseInputName]: testCaseInputValue,
                        };
                      })
                    ),
                  };
                }
              }),
            };
            sendQuestion.testCaseInputs === "[null]"
              ? delete sendQuestion.testCaseInputs
              : sendQuestion;
            (!sendQuestion.testcases?.length || sendQuestion.testcases[0] === undefined)
              ? delete sendQuestion.testcases
              : sendQuestion;
            sendQuestion.database.title === ""
              ? delete sendQuestion.database
              : sendQuestion;

            return sendQuestion;
          }),
        }
        if (taskId && editQuesStatus !== 'DRAFT') {
          await patchData(
            `${API_ASSESSMENT}/api/take-home-task/task/${taskId}`,
            JSON.stringify(questionBody)
          ).then(data => {
            setDialog(true);
          })
        } else {
          await postData(
            `${API_ASSESSMENT}/api/take-home-task`,
            JSON.stringify(questionBody)
          ).then(
            (res) => {
              postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'increase' }).then(data => {
                setData(res);
                setDialog(true);
              })
            },
            (err) => {
              console.error(err);
            }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false)
    }


  };

  const onSendToDraft = async (e) => {
    const taskId = id
    try {
      const questionBody = {
        ...(taskId && { taskId }),
        name: assessmentName,
        description: assessmentDescription,
        questions: questions?.map((item) => {
          const sendQuestion = {
            ...(taskId && { id: item.id }),
            name: item.nameQuestion,
            languageId: item.languages?.id,
            packageId: item.packages ? item.packages.id : null,
            databaseId: item?.database?.id ? item?.database?.id : null,
            database: {
              title: item.database.title,
              description: item.database.description,
              packageId: item.database.packages ? item.database.id : null,
              script: item.database.script,
            },
            description: item.questionDescription,
            candidateInstruction: JSON.stringify(
              draftToHtml(
                convertToRaw(item.candidateInstruction.getCurrentContent())
              )
            ),
            starterCode: item.starterCode,
            outputType: item.outputType,
            outputDescription: item.outputDescription,
            testCaseInputs: JSON.stringify(
              item.testCaseInputs?.map((item) => {
                if (item.name || item.description || item.type) {
                  return {
                    name: item.name,
                    description: item.description,
                    type: item.type,
                  };
                }
              })
            ),
            testcases: item.testCases?.map((testCase) => {
              if (testCase.output) {
                return {
                  id: testCase.id,
                  output: testCase.output,
                  input: JSON.stringify(
                    testCase.inputs.flatMap((i, index) => {
                      const testCaseInputType = item?.testCaseInputs?.[index]?.type;
                      let testCaseInputName = item?.testCaseInputs?.[index]?.name;
                      let testCaseInputValue = i.name;

                      if (testCaseInputType === DataTypes.ARRAY_INTEGER || testCaseInputType === DataTypes.ARRAY_STRING) {
                        testCaseInputValue = testCaseInputValue.map(el => testCaseInputType == DataTypes.ARRAY_INTEGER ? Number(el.value) : String(el.value));
                      }
                      else if (testCaseInputType === DataTypes.INTEGER) {
                        testCaseInputValue = Number(i.name);
                      }

                      return {
                        [testCaseInputName]: testCaseInputValue,
                      };
                    })
                  ),
                };
              }
            }),
          };
          sendQuestion.testCaseInputs === "[null]"
            ? delete sendQuestion.testCaseInputs
            : sendQuestion;
          sendQuestion.testcases[0] === undefined
            ? delete sendQuestion.testcases
            : sendQuestion;
          sendQuestion.database.title === ""
            ? delete sendQuestion.database
            : sendQuestion;

          return sendQuestion;
        }),
      }
      if (deletedQuestions.length) {
        await deletedQuestions.forEach(async id => {
          await deleteData(`${API_ASSESSMENT}/api/take-home-task/question/${id}`, {});
        })
        setDeletedQuestions([]);
      }
      if (taskId) {
        patchData(
          `${API_ASSESSMENT}/api/take-home-task/draft/task/${taskId}`,
          JSON.stringify(questionBody)
        ).then(data => setDialog(true))
      } else {
        postData(
          `${API_ASSESSMENT}/api/take-home-task/drafts`,
          JSON.stringify(questionBody)
        ).then(
          (res) => {
            setData(res);
            setDialogDraftQuestion(true);
          },
          (err) => {
            console.error(err);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }

  };
  useEffect(() => {
    const getDraftInfo = async () => await fetchData(`${API_ASSESSMENT}/api/take-home-task/task/${id}`, () => { }).then(draftData => {
      const draft = draftData?.data
      if (draft) {
        if (draft.status) {
          setEditQuesStatus(draft.status)
        }
        if (draft.name) {
          dispatch(setAssessmentName(draft.name))
        }
        if (draft.description) {
          dispatch(setAssessmentDescription(draft.description))
        }
        if (draft.questions?.length) {
          const ques = draft.questions?.map((item, index) => {
            let instruction = ''
            if (item?.candidateInstruction) {
              const parsedContent = JSON.parse(item.candidateInstruction);
              try {
                let editorState;
                if (parsedContent) {
                  const blocksFromHTML = convertFromHTML(parsedContent);
                  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                  editorState = EditorState.createWithContent(contentState);
                } else {
                  editorState = EditorState.createEmpty();
                }
                instruction = editorState
              } catch (error) {
                console.log("Error while storing instruction", error)
              }
            }
            const sendQuestion = {
              ...assessmentQuestion,
              id: item.id,
              formStep: 1,
              nameQuestion: item.name,
              languages: { id: item.languageId },
              packages: { id: item.packageId },
              database: { id: item.databaseId },
              questionDescription: item.description,
              candidateInstruction: instruction,
              starterCode: item.starterCode,
              outputType: item.outputType,
              outputDescription: item.outputDescription,
              testCaseInputs:
                item.testCaseInputs
                  ? JSON.parse(item.testCaseInputs)?.filter(Boolean)?.map((item) => {
                    if (item.name || item.description || item.type) {
                      return {
                        id: +(Math.random() * 1000).toFixed(0),
                        name: item.name,
                        description: item.description,
                        type: item.type,
                      };
                    }
                  }) : [
                    {
                      id: 0,
                      name: "",
                      type: "",
                      description: "",
                    },
                  ]

              ,
              isIncludeTestCases: !!item.testCaseInputs,
              testCases: item.testcases?.length ? item.testcases?.map((item) => {
                if (item.output) {
                  return {
                    id: item.id,
                    output: item.output,
                    inputs: JSON.parse(item.input)?.map((i, index) => {
                      const value = Object.values(i)[0]
                      return {
                        index: index + 1,
                        name: Array.isArray(value) ? value?.map((item1, idx) => {
                          return {
                            id: +(Math.random() * 1000).toFixed(0),
                            lable: `${Object.keys(i)}`,
                            value: item1,
                          }
                        }) : value,
                      }
                    })
                  }
                }
              }) : [
                {
                  id: 0,
                  inputs: [
                    {
                      index: 0,
                      name: null,
                    },
                  ],
                  output: null,
                },
              ],
            };
            return sendQuestion;
          })
          dispatch(setQuestions(ques))
        }
      }
    })
    if (id) {
      getDraftInfo().catch(err => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (questions.length) {
      let enabled = true;
      questions.forEach((homeQuestion) => {
        const validationFiled = validatedFields.find((que) => que.id === homeQuestion?.id)
        const isCompletedAllSteps = validationFiled && validationFiled?.isValidate;
        if (!isCompletedAllSteps) {
          enabled = false;
        }
      })
      setCanBeCreated(enabled)
    }
  }, [validFields, questions])

  const handleGotIt = () => {
    onDialog()
    dispatch(resetAssessmentTaskState());
  }

  return (
    <AuthGuard module="assessment" permission={["add", "edit"]}>
      <div className="create-home-task">
        <div className="create-live-task__container">
          <div className="create-home-task__header">
            <div className="task__header">
              <h1>
                {id ? "Edit a " : "Create a New "}Coding Assessments{" "}
                <button
                  className="task__header__select"
                  onClick={() => setUseTask(!useTask)}
                >
                  <h3 className="task__header-name">- TAKE HOME TASK</h3>
                  <img
                    alt="arrow-task"
                    className={`header__user__arrow ${useTask && "turn-arrow"}`}
                    src={arrowTask}
                  />
                </button>
              </h1>
              {useTask && (
                <ul className="task">
                  <li className="task__take">
                    <Link
                      className="task__item"
                      to="/coding-assessments/live-task"
                    >
                      <h3 className="task__name">LIVE TASK</h3>
                    </Link>
                    <Link
                      className="task__item"
                      to="/coding-assessments/take-home-task"
                    >
                      <h3 className="task__name">TAKE HOME TASK</h3>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="button-group">
              {screenWidth > 576 ? (
                <>
                  <button
                    onClick={() => {
                      dispatch(resetAssessmentTaskState());
                      if (
                        localStorage.getItem("prevRoute") ===
                        "/recruitment/position-workflow/create"
                      ) {
                        localStorage.setItem("prevRoute", "");
                        navigate("/recruitment/position-workflow/create");
                      } else {
                        navigate("/coding-assessments");
                      }
                    }}
                    className="button-group__cancel-button-task"
                  >
                    Cancel
                  </button>
                  <div className="button-group__edit-button-task">
                    <button
                      onClick={() => setDialogDraftQuestion(true)}
                      className="send-button-task"
                    >
                      <img
                        alt="send-task"
                        className="pen-icon"
                        src={sendToDraft}
                      />
                      Send to Draft
                    </button>
                  </div>
                  <Button
                  label= {isEdit() ? "Create" : "Save"}
                  isLoading={isLoading}
                  disabled={!canBeCreated}
                  onClick={onSave}
                  variant="primary"
                  className="btn btn--green button-group__create"
                  />
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      dispatch(resetAssessmentTaskState());
                      if (
                        localStorage.getItem("prevRoute") ===
                        "/recruitment/position-workflow/create"
                      ) {
                        localStorage.setItem("prevRoute", "");
                        navigate("/recruitment/position-workflow/create");
                      } else {
                        navigate("/coding-assessments");
                      }
                    }}
                    className="button-group__cancel-button-task"
                  >
                    <img src={backToGeneralArrow} alt="cancel-arrow" />
                  </button>
                  <Button
                  label= {isEdit() ? "Create" : "Save"}
                  isLoading={isLoading}
                  disabled={!canBeCreated}
                  onClick={onSave}
                  variant="primary"
                  // className="btn btn--green button-group__create"
                  />
                  <button
                    onClick={() => setShowDraft(!showDraft)}
                    className={cn("button-group__visibility-switcher", {
                      ["active"]: showDraft,
                    })}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_6557_59203)">
                        <path
                          d="M13 3V10H19L11 21V14H5L13 3Z"
                          stroke="#099C73"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6557_59203">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {showDraft && (
                    <div className="button-group__edit-button-task">
                      <button
                        onClick={() => setDialogDraftQuestion(true)}
                        className="send-button-task"
                      >
                        <img
                          alt="send-task"
                          className="pen-icon"
                          src={sendToDraft}
                        />
                        Send to Draft
                      </button>
                    </div>
                  )}
                </>
              )}

              {dialogDraftQuestion && (
                <DialogDraftQuestion
                  onDialog={setDialogDraftQuestion}
                  onSendToDraft={onSendToDraft}
                />
              )}
              {dialog && <DialogCreate onDialog={onDialog} onConfirm={handleGotIt} editMode={!isEdit()} />}
            </div>
          </div>
          <div>
            <div className="take-home-tasks">
              {formQuestions && screenWidth > 576 && (
                <div className="go-back-container">
                  <div className="go-back-container__button-white">
                    <div style={{ display: "flex" }}>
                      <button
                        onClick={() => dispatch(setFormQuestions(false))}
                        className="go-back-container__back-button"
                      >
                        <img
                          className="go-back-container__back-img"
                          src={backToGeneralArrow}
                          alt="cross-question"
                        />
                        <div className="go-back-container__content">
                          Back to General
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {formQuestions ? (
                <div className="add-question">
                  {screenWidth > 576 ||
                    (screenWidth < 576 && questions?.length < 2) ? (
                    questions?.map((homeQuestion, index) => {
                      const numberQuestion = questions.findIndex(
                        (findValue) => findValue.id === homeQuestion.id
                      );
                      const validationFiled = validatedFields.find((que) => que.id === homeQuestion?.id)
                      const isCompletedAllSteps = validationFiled && validationFiled?.isValidate;

                      const isActiveTab = +activeTab === index;
                      return (
                        <button
                          data-tab={index}
                          key={homeQuestion?.id}
                          onClick={(e) => handleClickActiveTab(e, index)}
                          className={`add-question__button ${isActiveTab && screenWidth > 576
                            ? "active-class"
                            : ""
                            } ${isActiveTab && screenWidth > 576
                              ? "gradient-blue"
                              : "gradient-white"
                            } `}
                        >
                          <section
                            data-tab={index}
                            className="add-question__button__ol-decimal"
                          >
                            {
                              <ShowToolTipComponent text={isCompletedAllSteps
                                ? 'All fields are filled. You can now update/create the question.'
                                : 'All fields must be filled in the question. If the question is not needed, remove it.'}>
                                <img
                                  alt="status"
                                  className="add-question__status"
                                  src={
                                    isCompletedAllSteps
                                      ? circleCheck
                                      : consoleErrorIcon
                                  }
                                />
                              </ShowToolTipComponent>
                            }

                            <span
                              data-tab={index}
                              className="add-question__button__sharp"
                            >
                              #{numberQuestion + 1}
                            </span>
                            <div
                              data-tab={index}
                              className="add-question__title"
                            >
                              {homeQuestion.nameQuestion === ""
                                ? "Untitled question"
                                : homeQuestion.nameQuestion}
                            </div>
                          </section>
                          <button
                            className="add-question__button__cross-img"
                            onClick={(e) => {
                              setDialogDeleteQuestion(true);
                              setDeleteId({
                                event: e,
                                id: homeQuestion?.id,
                                message: `#${numberQuestion + 1} ${homeQuestion.nameQuestion === ""
                                  ? "Untitled question"
                                  : homeQuestion.nameQuestion
                                  }`,
                              });
                            }}
                          >
                            {isActiveTab ? (
                              <img src={crossQuestion} alt="cross-question" />
                            ) : (
                              <img
                                src={crossQuestionWhite}
                                alt="cross-question"
                              />
                            )}
                          </button>
                        </button>
                      );
                    })
                  ) : (
                    <button
                      data-tab={+activeTab}
                      key={questions[+activeTab || 0].id}
                      onClick={() => setIsOpenedTabs(!isOpenedTabs)}
                      className={`add-question__button ${isOpenedTabs ? "active-class" : ""
                        } ${isOpenedTabs ? "gradient-blue" : "gradient-white"}`}
                    >
                      <section
                        data-tab={+activeTab}
                        className="add-question__button__ol-decimal"
                      >
                        <span
                          data-tab={+activeTab}
                          className="add-question__button__sharp"
                        >
                          #{+activeTab + 1}
                        </span>
                        <div
                          data-tab={+activeTab}
                          className="add-question__title"
                        >
                          {questions[+activeTab || 0].nameQuestion === ""
                            ? "Untitled question"
                            : questions[+activeTab || 0].nameQuestion}
                        </div>
                      </section>
                      <button
                        className="add-question__button__arrow-img"
                        onClick={() => setIsOpenedTabs(!isOpenedTabs)}
                      >
                        <img
                          alt="arrow-task"
                          className={`header__user__arrow ${isOpenedTabs && "turn-arrow"
                            }`}
                          src={arrowTask}
                        />
                      </button>
                    </button>
                  )}
                  {dialogDeleteQuestion && (
                    <DialogDeleteQuestion
                      onDialogDelete={setDialogDeleteQuestion}
                      onDelete={onDelete}
                      deleteId={deleteId}
                    />
                  )}

                  {questions.length < 5 && (
                    <div className="cross-add-new-question-wrapper">
                      <AddQuestionTask assessmentQuestion={assessmentQuestion} />
                    </div>
                  )}

                  {questions.length === 5 && screenWidth > 576 && (
                    <div className="questions-limit">limit reached</div>
                  )}

                  {isOpenedTabs && (
                    <div className="add-question__dropdown-wrapper">
                      {screenWidth < 576 &&
                        questions?.map((homeQuestion, index) => {
                          const numberQuestion = questions.findIndex(
                            (findValue) => findValue.id === homeQuestion.id
                          );
                          const isActiveTab = +activeTab === index;
                          return (
                            <button
                              data-tab={index}
                              key={homeQuestion?.id}
                              onClick={(e) => {
                                setIsOpenedTabs(!isOpenedTabs);
                                handleClickActiveTab(e, index);
                              }}
                              className={"add-question__button"}
                            >
                              <section
                                data-tab={index}
                                className="add-question__button__ol-decimal"
                              >
                                <span
                                  data-tab={index}
                                  className="add-question__button__sharp"
                                >
                                  #{numberQuestion + 1}
                                </span>
                                <div
                                  data-tab={index}
                                  className="add-question__title"
                                >
                                  {homeQuestion.nameQuestion === ""
                                    ? "Untitled question"
                                    : homeQuestion.nameQuestion}
                                </div>
                              </section>
                              <button
                                className="add-question__button__cross-img"
                                onClick={(e) => {
                                  setDialogDeleteQuestion(true);
                                  setDeleteId({
                                    event: e,
                                    id: homeQuestion?.id,
                                    message: `#${numberQuestion + 1} ${homeQuestion.nameQuestion === ""
                                      ? "Untitled question"
                                      : homeQuestion.nameQuestion
                                      }`,
                                  });
                                }}
                              >
                                <img src={crossQuestion} alt="cross-question" />
                              </button>
                            </button>
                          );
                        })}
                      {questions.length === 5 && isOpenedTabs && (
                        <div className="questions-limit">limit reached</div>
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {!formQuestions && (
              <div className="home-general">
                <HomeTaskStepGeneral
                  validFields={validFields}
                  setValidFields={setValidFields}
                />
              </div>
            )}
            <div>
              {formQuestions &&
                questions?.map((homeQuestion, index) => {
                  const isCurrentQuestion =
                    +activeTab === index || activeTab === undefined;
                  return (
                    <CSSTransition
                      classNames="my-node"
                      in={isCurrentQuestion}
                      mountOnEnter
                      unmountOnExit
                      key={index}
                      timeout={500}
                    >
                      <div
                        key={homeQuestion?.id}
                        className="take-home-task"
                        style={{ zIndex: `${index + 1}` }}
                      >
                        {homeQuestion.formStep > 0 ? (
                          <ProgressBar
                            setCanBeCreated={setCanBeCreated}
                            homeQuestion={homeQuestion}
                            isIncludeTestCases={homeQuestion?.isIncludeTestCases}
                          />
                        ) : null}
                        {homeQuestion.formStep === 1 && (
                          <HomeTaskStepOne
                            validFields={validFields}
                            setValidFields={setValidFields}
                            requiredFieldByCreateButton={requiredFieldByCreateButton()}
                            isNameQuestion={isNameQuestion}
                            setIsNameQuestion={setIsNameQuestion}
                            homeQuestion={homeQuestion}
                            isCurrentQuestion={isCurrentQuestion}
                          />
                        )}
                        {homeQuestion.formStep === 2 && (
                          <HomeTaskStepTwo
                            validFields={validFields}
                            setValidFields={setValidFields}
                            requiredFieldByCreateButton={requiredFieldByCreateButton()}
                            homeQuestion={homeQuestion}
                            isCurrentQuestionData={isCurrentQuestion}
                          />
                        )}
                        {homeQuestion.formStep === 3 && (
                          <HomeTaskStepThree
                            validFields={validFields}
                            setValidFields={setValidFields}
                            validFieldsButtonCreate={validFieldsButtonCreate}
                            homeQuestion={homeQuestion}
                            isCurrentQuestionData={isCurrentQuestion}
                            isNameQuestion={isNameQuestion}
                          />
                        )}
                      </div>
                    </CSSTransition>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default React.memo(HomeTask);
